<template>
  <el-dialog
    id="SelectFileForImportOrgFromXLSShortDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="520px"
    close-on-press-escape
    append-to-body
    class="my-dialog abow_dialog"
    @open="onOpen"
    @opened="onShow"
    @close="onClose"
    destroy-on-close
    @keydown.enter.native="onCommit"
  >
    <el-form
      ref="SelectFileForImportOrgFromXLSShortForm"
      @submit.native.prevent="onSubmit"
      v-loading="loading"
      :element-loading-text="loadingText"
      :model="model"
      :rules="rules"
    >
      <el-row justify="space-between" type="flex">
        <el-col>
          <el-card shadow="never" border="dashed" class="file">
            <el-upload
              ref="uploadInput"
              :action="uploadAction"
              :headers="uploadHeaders"
              class="upload-demo"
              list-type="text"
              :on-change="onFileSelect"
              :before-remove="onFileSelectRemove"
              :limit="1"
              :file-list="fileList"
              show-file-list
              accept=".xlsx,.xls"
              :auto-upload="false"
              :on-error="handleUploadError"
              :on-success="handleUploadSuccess"
              :before-upload="handleUploadBefore"
            >
              <el-button size="small" type="success">{{ $t("Оберіть файл") }}</el-button>
              <div slot="tip" class="el-upload__tip">{{ $t("Підтримуються файли *.xlsx,*.xls") }}</div>
            </el-upload>
          </el-card>
        </el-col>
      </el-row>
      <el-row justify="space-between" type="flex" :gutter="12">
        <el-col :span="12">
          <div class="group-header">{{ $t("Сторінка, рядок") }}</div>
          <el-card shadow="never" border="dashed">
            <el-form-item prop="pageNumber" :label="$t('Номер сторінки в книзі')">
              <el-input v-model="model.pageNumber" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="rowNumber" :label="$t('Початковий рядок')">
              <el-input v-model="model.rowNumber" autocomplete="off"></el-input>
            </el-form-item>
          </el-card>
        </el-col>
        <el-col :span="12">
          <div class="group-header">{{ $t("Номери стовпчиків") }}</div>
          <el-card shadow="never">
            <el-form-item prop="axiomaIdColNumber" label="AxiomaID">
              <el-input v-model="model.axiomaIdColNumber" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="okpoColNumber" :label="$t('ЄДРПОУ')">
              <el-input v-model="model.okpoColNumber" autocomplete="off"></el-input>
            </el-form-item>
          </el-card>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
    <ImportOrg @import-commit="commitOrgList" />
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";

export default {
  name: "SelectFileForImportOrgFromXLS",
  props: ["busEvent"],
  components: { ImportOrg: () => import("@/components/forms/ImportOrg") },
  data() {
    var that = this;
    return {
      loading: false,
      loadingText: "",
      dialogShow: false,
      title: $tt("Завантаження файлів"),
      operation: null,
      fileList: [],
      model: {},
      initModel: {},
      importRequestTimerBegin: 0,
      importRequestTimerTotal: 0,
      importTimer: null,
      maxRequestTime: 60 * 1000 * 5,
      fastTimeout: 10 * 1000,
      rules: {
        pageNumber: [this.fieldValidators.defaultValidateRule, { validator: this.fieldValidators.digitalValueValidateRule }],
        rowNumber: [this.fieldValidators.defaultValidateRule, { validator: this.fieldValidators.digitalValueValidateRule }],
        axiomaIdColNumber: [{ validator: this.fieldValidators.digitalValueValidateRule }, { validator: this.axiomaOrOKPOFieldValidator }],
        okpoColNumber: [{ validator: this.fieldValidators.digitalValueValidateRule }, { validator: this.axiomaOrOKPOFieldValidator }],
      },
    };
  },

  methods: {
    axiomaOrOKPOFieldValidator(rule, value, callback) {
      if (this.model.axiomaIdColNumber || this.model.okpoColNumber) {
        callback();
      } else {
        callback(new Error($tt("Це поле має бути заповненим")));
      }
    },
    onCommit() {
      if (this.fileList.length == 1) {
        this.$refs.SelectFileForImportOrgFromXLSShortForm.validate((valid) => {
          if (valid) {
            this.$store.dispatch("main/updateUserSettings", { importOrgFromXLS: this.model });
            this.loading = true;
            this.loadingText = $tt("Завантаження...");
            this.$refs.uploadInput.submit();
          } else {
            this.$message.error($tt("Перевірте введені дані!"));
          }
          return false;
        });
      } else {
        this.$message.error($tt("Не обрано файл для імпорту!"));
      }
    },
    onOpen() {},
    onShow() {
      this.$refs.SelectFileForImportOrgFromXLSShortForm.resetFields();
      this.fileList = [];
      var importSettings = this.$store.getters["main/userSettings"]("importOrgFromXLS");
      var lang = this.initModel && this.initModel.lang ? this.initModel.lang : "ua";
      this.model = _.extend({}, this.initModel, importSettings, { lang: lang });
      delete this.model.orgExists;
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    onClose() {
      this.$store.dispatch("main/cancelAllRequests");
    },
    onFileSelect(file, fileList) {
      this.fileList = fileList;
      //fileList.filter((file) => file.raw.type == "application/json");
    },
    onFileSelectRemove(file, fileList) {
      this.fileList = [];
      return true;
    },
    handleUploadError(err) {
      this.loading = false;
      globalErrorMessage($tt("Помилка завантаження!"), err && err.message ? JSON.parse(err.message).msg : err);
      //this.$message.error("Upload error! " + err);
    },
    handleUploadSuccess(data) {
      if (data && data.success) {
        this.$message.success($tt("Файл завантажено [{id}]!", { id: data.data.tmpFileName }));
        this.loadingText = $tt("Будь ласка, зачекайте...");
        this.runRequestTimer();
        this.$store
          .dispatch("orgs/importFromUploadedFileXLS", {
            fileName: data.data.tmpFileName,
            params: this.model,
          })
          .finally(() => {
            this.stopRequestTimer();
            this.loading = false;
            this.$nextTick(() => {
              this.$refs.uploadInput.clearFiles();
              this.fileList = [];
            });
          })
          .then((data) => {
            if (data && data.data && data.data.length) {
              bus.$emit("forms.orgs.import.show", { title: $tt("Результат імпорту"), orgExists: this.initModel.orgExists });
            }
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка!"), err);
          });
      } else {
        this.$message.error($tt("Помилка!") + data.msg);
      }
    },
    handleUploadBefore(file) {
      const isLessThan100M = file.size <= 100 * 1024 * 1024; // 100M
      if (!isLessThan100M) {
        this.$message.error($tt("Розмір файлу має бути меньшим за 100 Mб!"));
        this.loading = false;
      }
      return isLessThan100M;
    },
    commitOrgList(data) {
      if (data && data.data && data.data.length > 0) {
        this.dialogShow = false;
        this.$emit("commit", { data: data.data, operation: this.operation, options: data.options });
      }
    },
    runRequestTimer() {
      this.importRequestTimerBegin = new Date().getTime();
      this.importTimer = setInterval(() => {
        var currentTime = new Date().getTime();
        if (currentTime - this.importRequestTimerBegin > this.fastTimeout) {
          this.loadingText =
            $tt("Будь ласка, зачекайте...") + "[" + this.milisecondsToMinSec(this.maxRequestTime - (currentTime - this.importRequestTimerBegin)) + "]";
        }
      }, 1000);
    },
    stopRequestTimer() {
      if (this.importTimer) clearInterval(this.importTimer);
    },
  },
  created() {
    bus.$on(this.busEvent + ".show", (data) => {
      this.loading = true;
      this.title = (data && data.title) || this.title;
      this.initModel = _.extend({ pageNumber: 1, rowNumber: 1, axiomaIdColNumber: 1, okpoColNumber: 2 }, data.initModel);
      this.operation = data.operation;
      this.dialogShow = true;
    });
    bus.$on(this.busEvent + ".hide", () => {
      this.dialogShow = false;
    });
    bus.$on(this.busEvent + ".clear", () => {
      this.fileList = [];
      this.$refs.uploadInput.clearFiles();
    });
  },

  computed: {
    uploadAction() {
      return axios.defaults.baseURL + "/api/axioma/upload/xls";
    },
    uploadHeaders() {
      return {
        token: this.$store.getters["main/getToken"](),
      };
    },
  },
};
</script>
<style lang="scss">
#SelectFileForImportOrgFromXLSShortDialog {
  .el-dialog {
    max-height: 460px !important;
  }
}
#SelectFileForImportOrgFromXLSShortDialog {
  .el-card {
    border: 1px dashed #ebeef5;
  }
  .el-card__body {
    padding: 16px;
  }
  .group-header {
    font-size: 17px;
    line-height: 24px;
  }
}
</style>
